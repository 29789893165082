import React from 'react'
import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
import QualityBanner from "../Images/background/QualityBanner.jpg"
function QualityAssurance() {
    return (
      <Layout>
        <Banner title="Quality Assurance" />
        <div className="section">
          <div className="content-wrap">
            <div className="container">
              <div className="row mb-5" id="qualityPolicy">
                <div className="col-sm-12 col-md-4">
                  <img src={QualityBanner} alt="" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-md-8">
                  <h4 className="text-primary  uk21">Quality Assurance :-</h4>
                  <div>
                    <p>
                      {" "}
                      Quality is tested on several parameters to ensure that
                      clients get the best features in the components delivered
                      by us.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> Separate QC
                      department.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> In line
                      final product inspection.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> Display of
                      charts and drawings at various workstations and machines.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> Collection/
                      accumulation of data on daily basis.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> Process
                      calibration/ equipment setting using past data and
                      statistical tools.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> Strict
                      adherence to customer drawings and needs.
                    </p>
                    <p>
                      {" "}
                      <i className="fas fa-angle-double-right"></i> In addition,
                      we have a fully equipped Testing Facility that boasts of
                      latest instruments used for the following :-
                    </p>
                    <ul>
                      <li>Heat Treatment checking.</li>
                      <li>Run out checking.</li>
                      <li>Profile checking.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
}

export default QualityAssurance
